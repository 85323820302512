<template>
  <NotificationCard />
  <div class="grid justify-content-center ml-0">
    <Card class="col-11 mb-5 mt-3 ">
      <template #content>
        <div div v-if="isLoadingTickets">
          <LoadingSpinner :loading="isLoadingTickets" />
        </div>
        <!-- BEGIN WRAPPER DIV -->
        <div v-else>
          <!-- BEGIN TICKET VIEW FIELDS -->
          <div class="grid justify-content-center">
            <div class="col-12" style="display: flex; justify-content: end;">
              <Button class="split-sales-order-btn" label="Print/Export" icon="pi pi-print" @click="printTable"></Button>
            </div>
            <div class="col-12 mt-1">
              <DataTable
                class="p-datatable-sm"
                v-model:expandedRows="expandedRows"
                @rowExpand="onRowExpand"
                :row-class="() => {
                  return 'cursor-pointer'
                }"
                @row-click="rowClicked"
                @sort="sortTickets"
                :sortField="sort.sortBy"
                :sortOrder="sort.sortOrder"
                :value="items"
                :dataKey="id"
                filterDisplay="menu"
                v-model:filters="filters"
                @page="onPage"
                paginator
                :rows="rows"
                @update:rows="rows = $event"
                v-model:first="this.getPage"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[5, 10, 15, 20, 25]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Tickets"
              >
                <Column expander style="width: 5rem" />
                <Column field="id" header="ID" :showFilterMatchModes="false" :showFilterOperator="false" sortable>
                  <template #body="{data}">
                    {{ data.id }}
                  </template>
                  <template #filter>
                    <InputText
                      type="text"
                      v-model="selectedTicketID"
                      class="p-column-filter"
                      placeholder="Search by Id"
                    />
                  </template>
                  <template #filterclear>
                    <Button
                      type="button"
                      label="Clear"
                      @click="selectedTicketID = ''; fetchFilteredTickets(false)"
                    />
                  </template>
                  <template #filterapply>
                    <Button
                      type="button"
                      label="Apply"
                      @click="fetchFilteredTickets(false)"
                    />
                  </template>
                </Column>
                <Column field="title" header="Title" />
                <column field="closed" header="Status" :showFilterMatchModes="false" :showFilterOperator="false" >
                  <template #body="{data}">
                    <span class="status-column">{{ data.closed === 'Y' ? 'Closed' : 'Open' }}</span>
                  </template>
                  <template #filter>
                    <Dropdown
                      v-model="selectedStatus"
                      :options="status"
                      optionLabel="status"
                      optionValue="initial"
                      placeholder="Filter By Status"
                      filterMatchMode="startsWith"
                      :style="{ width: '150px' }"
                    />
                  </template>
                  <template #filterclear>
                    <Button
                      type="button"
                      label="Clear"
                      @click="clearStatus"
                    />
                  </template>
                  <template #filterapply>
                    <Button
                      type="button"
                      label="Apply"
                      @click="fetchFilteredTickets(false)"
                    />
                  </template>
                </column>
                <Column field="assigned_to" header="Assignee" sortable/>
                <Column field="sortableDate" header="Date" sortable >
                  <template #body="{data}">
                    {{ data.sortableDate.toLocaleDateString() }}
                  </template>
                </Column>
                <Column field="type" header="Type" :showFilterMatchModes="false" :showFilterOperator="false">
                  <template #body="{data}">
                    {{data.type }}
                  </template>
                  <template #filter>
                    <Dropdown
                      v-model="selectedType"
                      :options="getBillingTypeItems"
                      optionLabel="billing_type"
                      optionValue="billing_type"
                      placeholder="Filter by Type"
                      :style="{ width: '150px' }"
                    />
                  </template>
                  <template #filterclear>
                    <Button
                      type="button"
                      label="Clear"
                      @click="selectedType = null; fetchFilteredTickets(false)"
                    />
                  </template>
                  <template #filterapply>
                    <Button
                      type="button"
                      label="Apply"
                      @click="fetchFilteredTickets(false)"
                    />
                  </template>
                </Column>
                <template #expansion="slotProps">
                  <div class="mb-2">
                    <TicketForm
                      :active="active"
                      :ticket="slotProps.data"
                      :isEditingTicket="true"
                      :billing="this.billing"
                      :first="first"
                      :rows="rows"
                      :showCancelButton="false"
                      @onTicketBilling="
                        (event) => {
                          if (event.success) this.getBillRecords(slotProps.data);
                        }
                      "
                      @onStatusUpdate="
                        (event) => {
                          if (
                            event.success &&
                            this.selectedStatus !== null &&
                            this.selectedStatus
                          )
                            fetchFilteredTickets(false);
                        }
                      "
                      @onSave="
                        (event) => {
                          if (event.success) fetchFilteredTickets(false);
                        }
                      "
                    />
                  </div>
                  <loading-spinner :loading="isLoadingBills" />
                  <DataTable
                    v-if="!isLoadingBills"
                    :value="bills"
                    tableStyle="width:fixed"
                    class="p-datatable-sm"
                  >
                    <Column field="user" header="User" />
                    <Column field="hours" header="Hour" />
                    <Column field="date" header="Date" sortable />
                    <Column
                      field="desc"
                      header="Comment"
                      style="line-break: anywhere; word-break: keep-all"
                    >
                      <template #body="slotProps">
                        <p :title="slotProps.data.desc">
                          {{ slotProps.data.desc }}
                        </p>
                      </template>
                    </Column>
                    <template #empty> No billing information to show. </template>
                  </DataTable>
                </template>
                <template #empty> Tickets are not able to show.</template>
              </DataTable>
            </div>
          </div>
          <!-- END OF TICKET VIEW FIELDS -->
        </div>
        <!-- END WRAPPER DIV -->
      </template>
    </Card>
  </div>
  <Footer />
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import NotificationCard from "@/components/Notification-Card.vue";
import Card from "primevue/card";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Footer from "@/components/Footer.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import BillingService from "@/services/BillingService";
import TicketForm from "@/components/TicketForm.vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
export default defineComponent({
  components: {
    NotificationCard,
    Card,
    LoadingSpinner,
    Footer,
    Dropdown,
    InputText,
    Button,
    DataTable,
    Column,
    TicketForm,
  },
  created() {
    this.fetchFilteredTickets(false);

    this.billControl({
      id: "BILL",
      filename: "CONTROL",
      control: "BILL.CONTROL",
      procedure: "BILL.CONTROL",
      getter: this.getBillingTypeItems,
    });
  },
  computed: {
    ...mapGetters({
      getPage: "billing/getPage",
      getRange: "billing/getRange",
      getTickets: "billing/getTickets",
      getLength: "billing/getLength",
      getCust: "customer/getCust",
      getBillingTypeItems: "billing/getBillingTypeItems",
    }),
  },
  data() {
    return {
      printExportItems: [],
      items: [],
      totalRecords: 0,
      maxExportRows: 1000,
      rangeStart: 1,
      rangeEnd: 10,
      billingService: new BillingService(process.env.VUE_APP_ABSTRACTION_API),
      bills: [],
      active: null,
      selectedStatus: "O",
      selectedType: null,
      selectedTicketID: "",
      columnStyle: "col-12 md:col-1 md:flex-grow-1 lg:col-1 lg:flex-grow-1",
      currentTicketID: 0,
      isLoadingTickets: false,
      isLoadingBills: false,
      expandedRows: [],
      first: 0,
      page: 1,
      sort: {
        sortBy: "",
        sortOrder: 0,
      },
      pagination: [
        { num: 5 },
        { num: 10 },
        { num: 15 },
        { num: 20 },
        { num: 25 },
      ],
      status: [
        { status: "Open", initial: "O" },
        { status: "Closed", initial: "C" },
      ],
      allExportColumns: [
        { field: "id", header: "ID", input: true },
        { field: "title", header: "Title", input: true },
        { field: "type", header: "Type", input: true },
        { field: "assigned_to", header: "Assigned To", input: true },
        { field: "closed", header: "Status", input: true },
        { field: "sortableDate", header: "Date", input: true },
      ],
      dynamicColumns: [
        { field: "id", header: "ID", input: true },
        { field: "title", header: "Title", input: true },
        { field: "type", header: "Type", input: true },
        { field: "assigned_to", header: "Assigned To", input: true },
        { field: "closed", header: "Status", input: true },
        { field: "sortableDate", header: "Date", input: true },
      ],
      isLoadingTicket: false,
      ticketArray: null,
      billing: false,
      rows: 5,
      filters: {
        cust_name: {
          value: [],
          matchMode: FilterMatchMode.IN,
        },
        type: {
          value: [],
          matchMode: FilterMatchMode.IN,
        },
        assigned_to: {
          value: [],
          matchMode: FilterMatchMode.IN,
        },
        id: {
          value: "",
          matchMode: FilterMatchMode.EQUALS,
        },
        closed: {
          value: [""],
          matchMode: FilterMatchMode.IN,
        },
      },
    };
  },
  methods: {
    ...mapActions({
      fetchTickets: "billing/fetchTickets",
      setNextPage: "billing/setNextPage",
      addNotification: "notification/add",
      billControl: "billing/getControls",
      clearPrintData: "printableDatatable/clearData",
      setPrintData: "printableDatatable/setData",
      setPrintDefaultColumns: "printableDatatable/setDefaultColumns",
      setPrintAvaialbleColumns: "printableDatatable/setAvaialbleColumns",
    }),
    async printTable() {
      //block a print with no criteria applied
      if(this.totalRecords === 0) {
        const notification = {
          message: `No records to print or export.`,
          type: "error",
        };
        this.addNotification(notification);
        return;
      }
      if (this.totalRecords > this.maxExportRows) {
        const notification = {
          message: `Please filter the results before printing.`,
          type: "error",
        };
        this.addNotification(notification);
      } else {
        this.clearPrintData();
        // get full dataset for the current criteria
        await this.fetchFilteredTickets(false);
        this.setPrintData(this.printExportItems);
        this.setPrintDefaultColumns(this.dynamicColumns);
        this.setPrintAvaialbleColumns(this.allExportColumns);

        window.open(
          "/printable-view?print=1&showSelection=1",
          "_blank"
        );
      }
    },
    rowClicked(event) {
      if (event.originalEvent.target.tagName === 'TD') {
        const found = this.expandedRows.find((ticket) => ticket.id === event.data.id);
        if(found) {
          // Not sure why this works
          this.expandedRows.pop(event.index)
        } else {
          this.expandedRows.push(event.data)
          this.onRowExpand(event)
        }
      }
    },
    sortTickets(event) {
      this.sort.sortOrder = event.sortOrder
      this.sort.sortBy = event.sortField
      this.fetchFilteredTickets(false)
    },
    fetchFilteredTickets(addTicket) {
      this.active = null;
      this.isLoadingTickets = true;

      this.filters.id.value = this.selectedTicketID;

      switch (this.selectedStatus) {
        case "O":
          this.filters.closed.value = ["N", ""];
          break;
        case "C":
          this.filters.closed.value = ["Y"];
          break;
        default:
          this.filters.closed.value = [];
          break;
      }

      let sortBy
      switch(this.sort.sortBy) {
        case "assigned_to":
          sortBy = "assigned.to";
          break;
        case "sortableDate":
          sortBy = "date";
          break;
        default:
          sortBy = this.sort.sortBy
          break;
      }

      let types = [];

      if (this.selectedType == null) {
        types = [];
      } else {
        types = [this.selectedType];
      }

      this.fetchTickets({
        cust: this.getCust.cust_id,
        ids: this.selectedTicketID,
        status: this.selectedStatus,
        addTicket: addTicket,
        types: types,
        sortOrder: this.sort.sortOrder === -1 ? "DEC" : "",
        sortBy: sortBy
      })
        .then((resp) => {
          this.items = resp.data.map((item) => {
            return {
              ...item,
              sortableDate: new Date(item.date),
            };
          });

          this.printExportItems = resp.data.map((item) => {
              return {
                ...item,
                closed: item.closed === "Y" ? "Closed" : "Open",
                sortableDate: new Date(item.date),
              };
          });

          this.totalRecords = resp.data.length;
        })
        .catch(() => {
          this.isLoadingTickets = false;
        }).finally(() => {
          this.isLoadingTickets = false;
        });
      if (!addTicket) {
        this.page = 1;
        this.first = 0;
      }
    },
    onPage(event) {
      this.setNextPage(event.first);
      this.active = -1;
      if (
        (event.page === event.pageCount ||
          event.page === event.pageCount - 1 ||
          event.page === event.pageCount - 2) &&
        this.getLength == this.getRange - 100
      ) {
        this.fetchFilteredTickets(true);
      }
    },
    getBillRecords(ticket) {
      this.isLoadingBills = true;
      this.bills = []; // resets the bill items in the components
      this.billingService
        .getbills(ticket.id)
        .then((response) => {
          this.bills = response.bill_items;
          this.isLoadingBills = false;
        })
        .catch((error) => {
          this.addNotification({
            type: error,
            message: error.message,
          });
          this.isLoadingBills = false;
        });
    },
    clearTicketSearchBar() {
      this.selectedTicketID = "";
      this.filters.id.value = "";
      this.fetchFilteredTickets(false);
    },
    clearStatus() {
      this.filters.closed.value = [];
      this.selectedStatus = null;
      this.fetchFilteredTickets(false);
    },
    onRowExpand(event) {
      this.active = event.data;
      this.getBillRecords(event.data);
    },
  },
});
</script>
