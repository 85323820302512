import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store"

export default class BillingService {
    private readonly client: AxiosInstance;
    constructor(serviceUrl: string) {
      this.client = axios.create({
        baseURL: serviceUrl,
        withCredentials: false,
      });
    }

    async getbills(ticket: string){
        let token = ""
        await store.dispatch('session/fetchToken').then((response) => { token = response })
        const authToken = "Bearer " + token
        const params = {
          ticket
        }
        return this.submitGet(`bills`,authToken,params)
    }

    async postBillingInfo(payload: any) {
      let token = ""
      await store.dispatch('session/fetchToken').then((response) => { token = response })
      const authToken = "Bearer " + token
      return this.submitPost(`bill`, authToken,{bill:{...payload}});
    }

    private async submitGet(endpoint: string, token: string, params: any) {
        return new Promise((resolve, reject) => {
          this.client
            .get(endpoint, { 
              params,
              headers:
              {
                  'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
                  'Authorization': token
              }
             })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
        });
      }

      private async submitPost(
        endpoint: string,
        token: string,
        payload: any,
        params?: any
      ) {
        return new Promise((resolve, reject) => {
          this.client
            .post(endpoint, payload, { 
              params,
              headers:
              {
                  'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
                  'Authorization': token
              }
             })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }

}