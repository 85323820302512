
import { defineComponent } from "vue";
import Button from "primevue/button";
import InputSwitch from "primevue/inputswitch";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import TextArea from "primevue/textarea";
import InputNumber from "primevue/inputnumber";
import SplitButton from "primevue/splitbutton";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, between } from "@vuelidate/validators";
import Search from "@/components/Search.vue";
import TicketService from "@/services/TicketService";
import BillingService from "@/services/BillingService";
import CustomerService from "@/services/CustomerService";
import Bill from "../types/bill";
import Ticket from "../types/ticket";
import BillingTypeItem from "../types/billingTypeItem";
import Part from "@/types/part";
import { mapActions, mapGetters } from "vuex";
import CustResponse from "../types/Responses/custResponse";

export default defineComponent({
  name: "TicketForm",
  components: {
    Search,
    Calendar,
    InputSwitch,
    Button,
    Dropdown,
    InputText,
    TextArea,
    InputNumber,
    SplitButton,
  },
  emits: ["hide", "onSave", "onStatusUpdate", "onTicketBilling"],
  props: {
    show: Boolean,
    billing: Boolean,
    isEditingTicket: {
      type: Boolean,
      default: false,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    active: Number,
    ticket: Object,
    bill: Object,
    first: Number,
    rows: Number,
  },
  created() {
    this.isHidden = this.show;
    this.initFields();
    let currentUserDetails = this.getUser;
    this.currentUser = currentUserDetails.first_name.concat(" "+currentUserDetails.last_name);
  },
  mounted() {
    this.newTicket = this.ticket as Ticket;
  },
  computed: {
    ...mapGetters({
      getBillingTypes: "billing/getBillingTypeItems",
      getUserIDs: "billing/getUserIdItems",
      getCustTypes: "billing/getCustTypeItems",
      getTickets: "billing/getTickets",
      getUser: "session/getUser",
      getCurrentIndex: "billing/getCurrentIndex",
    }),
    ticketFieldsHaveChanged(): any {
      return Object.keys(this.newTicket as Ticket).some(
        (field: string) =>
          this.newTicket[field as keyof Ticket] !==
          this.selectedTicket[field as keyof Ticket]
      );
    },
  },
    setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      newTicket: {
        type: { required },
      },
      newBill: {
        hours: {
          required: requiredIf(() => {
            if (this.isEditingTicket && !this.ticketFieldsHaveChanged)
              return true;
            return (
              this.newBill?.desc?.trim().length > 0 ||
              this.newBill?.notes?.trim().length > 0
            );
          }),
          between: between(0, 24),
        },
        date: {
          requiredIf: requiredIf(() => {
            return (
              this.newBill?.desc?.trim().length > 0 ||
              this.newBill?.notes?.trim().length > 0 ||
              !!this.newBill?.hours?.toString()
            );
          }),
        },
      },
    };
  },
  data() {
    return {
      isHidden: true,
      editTicketField: true,
      newTicket: {} as Ticket,
      selectedTicket: {} as Ticket,
      newBill: {} as Bill,
      currentUser: "",
      billable: false,
      newTicketCust: "",
      submitted: false,
      isLoadingTicket: false,
      parts: [] as Array<Part>,
      part: "" as string | null,
      saveOptions: [
        {
          label: "Save & Close Ticket",
          command: () => {
            this.saveAndClose();
          },
        },
        {
          label: "",
          command: () => {
            this.updateTicketStatus();
          },
        },
      ],
      ticketService: new TicketService(process.env.VUE_APP_ABSTRACTION_API),
      billService: new BillingService(process.env.VUE_APP_ABSTRACTION_API),
      custService: new CustomerService(process.env.VUE_APP_ABSTRACTION_API),
    };
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
      fetchTickets: "billing/getTickets",
      updateTicket: "billing/updateTicket",
      updateTicketRecord: "billing/updateTicketRecord",
      updateCurrentIndex: "billing/updateCurrentIndex",
      addTicketRecord: "billing/addTicketRecord"
    }),
    initCust() {
      if (this.newTicket.cust) {
        this.custService
          .fetchCustOptions(
            this.getTickets.slice(this.first, this.first! + this.rows!)[
              this.active!
            ]?.cust
          )
          .then((response) => {
            if ((response as CustResponse).cust_items.length > 0) {
              const cust = (response as CustResponse).cust_items[0];
              this.newTicketCust = cust.name;
            }
          });
      }
    },
    formatDate(date: string) {
      if (date == null || date == "") return (date = "");
      let stringdate = new Date(date)
        .toLocaleDateString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        })
        .split("/")
        .join("-");
      return stringdate;
    },
    sortArray(x: Part, y: Part) {
      if (x.part_no < y.part_no) {
        return -1;
      }
      if (x.part_no > y.part_no) {
        return 1;
      }
      return 0;
    },
    async saveTicket() {
      this.submitted = true;
      this.isLoadingTicket = true;

      if(!this.billing){
        this.newBill.date = new Date().toString();
        this.newBill.hours = "0.0"
        this.newBill.user = this.currentUser;
        this.newBill.date = this.formatDate(this.newBill.date);

        if((this.newBill.desc != undefined || this.newBill.notes != undefined) && (Object.keys(this.selectedTicket).length == 0 && this.ticketFieldsHaveChanged)){
          // Commenting on a ticket only
            this.billService
            .postBillingInfo({
              ...this.newBill,
              ticket: this.newTicket.id,
              cust_number: this.newTicket.cust,
            })
            .then(() => {
              this.addNotification({
                message: `Successfully Commented on Ticket Id ${this.newTicket.id}`,
                type: "success",
              });

              this.isLoadingTicket = false;
              this.submitted = false;
              this.newBill = {} as Bill; // reset fields

              this.$emit("onTicketBilling", { success: true });
            })
            .catch(() => {
              this.addNotification({
                message: `Could not comment on ticket Id ${this.newTicket.id}`,
                type: "error",
              });
              this.isLoadingTicket = false;
              this.$emit("onTicketBilling", { success: false });
            });
        } else if((this.newBill.desc == undefined && this.newBill.notes == undefined) && this.ticketFieldsHaveChanged) {
          // Updating a ticket only
        const oldTicket = this.getTickets.slice(
          this.first,
          this.first! + this.rows!
        )[this.active!]; // obtain from store

              this.ticketService
                .updateTicket({
                  newTicket: this.newTicket,
                  oldTicket: oldTicket,
                  ticket_id: this.newTicket.id,
                })
                .then(() => {
                  this.isLoadingTicket = false;

                  this.addNotification({
                    message: `Successfully updated against ticket Id ${this.newTicket.id}`,
                    type: "success",
                  });

                  this.updateTicketRecord(
                    JSON.parse(JSON.stringify(this.newTicket))
                  );
                  this.selectedTicket = JSON.parse(
                    JSON.stringify(this.newTicket as Ticket)
                  );

                  this.$emit("onSave", {
                    ticket: this.newTicket,
                    success: true,
                  });
                })
                .catch(() => {
                  this.isLoadingTicket = false;
                  this.addNotification({
                    message: `Error. Could not update ticket, Please Try again.`,
                    type: "error",
                  });
                  this.$emit("onSave", {
                    ticket: this.newTicket,
                    success: true,
                  });
                });
        } else if ((this.newBill.desc != undefined || this.newBill.notes != undefined) && (Object.keys(this.selectedTicket).length !== 0 && this.ticketFieldsHaveChanged)){
          // commenting and updating a ticket
          if (this.newTicket.assigned_to === "UA") {
            this.newTicket.assigned_to = "";
          }
          const oldTicket = this.getTickets.slice(
            this.first,
            this.first! + this.rows!
          )[this.active!]; // obtain from store


          this.billService
            .postBillingInfo({
              ...this.newBill,
              ticket: this.newTicket.id,
              cust_number: this.newTicket.cust,
            })
            .then(() => {
              this.$emit("onTicketBilling", { success: true });

              this.addNotification({
                message: `Successfully Billed Time against Ticket Id ${this.newTicket.id}`,
                type: "success",
              });

              this.ticketService
                .updateTicket({
                  newTicket: this.newTicket,
                  oldTicket: oldTicket,
                  ticket_id: this.newTicket.id,
                })
                .then(() => {
                  this.isLoadingTicket = false;

                  this.addNotification({
                    message: `Successfully updated ticket Id ${this.newTicket.id}`,
                    type: "success",
                  });

                  this.updateTicketRecord(
                    JSON.parse(JSON.stringify(this.newTicket))
                  );
                  this.selectedTicket = JSON.parse(
                    JSON.stringify(this.newTicket as Ticket)
                  );

                  this.$emit("onSave", {
                    ticket: this.newTicket,
                    success: true,
                  });
                })
                .catch(() => {
                  this.isLoadingTicket = false;
                  this.addNotification({
                    message: `Error. Could not update ticket, Please Try again.`,
                    type: "error",
                  });
                  this.$emit("onSave", {
                    ticket: this.newTicket,
                    success: true,
                  });
                });
            })
            .catch(() => {
              this.isLoadingTicket = false;
              this.addNotification({
                message: `Could not comment on ticket Id ${this.newTicket.id}`,
                type: "error",
              });
              this.$emit("onTicketBilling", { success: false });
            });
        } else {
          this.isLoadingTicket = false;
        }
      }
    },
    async saveAndClose() {
      this.submitted = true;

      if (this.newTicket.closed === "Y") return;

        if(!this.billing){
          this.isLoadingTicket = true;
          this.newBill.date = new Date().toString();
          this.newBill.hours = "0.0";
          this.newBill.user = this.currentUser;
          this.newBill.date = this.formatDate(this.newBill.date);

        if((this.newBill.desc != undefined || this.newBill.notes != undefined) && (Object.keys(this.selectedTicket).length === 0 && this.ticketFieldsHaveChanged)){
          // Commenting on a ticket only
            this.billService
            .postBillingInfo({
              ...this.newBill,
              ticket: this.newTicket.id,
              cust_number: this.newTicket.cust,
            })
            .then(() => {
              this.addNotification({
                message: `Successfully Commented on Ticket Id ${this.newTicket.id}`,
                type: "success",
              });

              this.isLoadingTicket = false;
              this.submitted = false;
              this.newBill = {} as Bill; // reset fields

              this.$emit("onTicketBilling", { success: true });
              this.postClosedTicket();
            })
            .catch(() => {
              this.addNotification({
                message: `Could not comment on ticket Id ${this.newTicket.id}`,
                type: "error",
              });
              this.isLoadingTicket = false;
              this.$emit("onTicketBilling", { success: false });
            });
        } else if((this.newBill.desc == undefined && this.newBill.notes == undefined) && this.ticketFieldsHaveChanged) {
          // Updating and closing a ticket
          const oldTicket = this.getTickets.slice(
            this.first,
            this.first! + this.rows!
          )[this.active!]; // obtain from store

              this.ticketService
                .updateTicket({
                  newTicket: this.newTicket,
                  oldTicket: oldTicket,
                  ticket_id: this.newTicket.id,
                })
                .then(() => {
                  this.isLoadingTicket = false;

                  this.addNotification({
                    message: `Successfully updated against ticket Id ${this.newTicket.id}`,
                    type: "success",
                  });

                  this.updateTicketRecord(
                    JSON.parse(JSON.stringify(this.newTicket))
                  );
                  this.selectedTicket = JSON.parse(
                    JSON.stringify(this.newTicket as Ticket)
                  );

                  this.$emit("onSave", {
                    ticket: this.newTicket,
                    success: true,
                  });
                  this.postClosedTicket();
                })
                .catch(() => {
                  this.isLoadingTicket = false;
                  this.addNotification({
                    message: `Error. Could not update ticket, Please Try again.`,
                    type: "error",
                  });
                  this.$emit("onSave", {
                    ticket: this.newTicket,
                    success: true,
                  });
                });
        } else if ((this.newBill.desc != undefined || this.newBill.notes != undefined) && (Object.keys(this.selectedTicket).length !== 0 && this.ticketFieldsHaveChanged)){
          // commenting, updating and closing a ticket
          if (this.newTicket.assigned_to === "UA") {
            this.newTicket.assigned_to = "";
          }
          const oldTicket = this.getTickets.slice(
            this.first,
            this.first! + this.rows!
          )[this.active!]; // obtain from store


          this.billService
            .postBillingInfo({
              ...this.newBill,
              ticket: this.newTicket.id,
              cust_number: this.newTicket.cust,
            })
            .then(() => {
              this.$emit("onTicketBilling", { success: true });

              this.addNotification({
                message: `Successfully Commented on against Ticket Id ${this.newTicket.id}`,
                type: "success",
              });

              this.ticketService
                .updateTicket({
                  newTicket: this.newTicket,
                  oldTicket: oldTicket,
                  ticket_id: this.newTicket.id,
                })
                .then(() => {
                  this.isLoadingTicket = false;

                  this.addNotification({
                    message: `Successfully updated ticket Id ${this.newTicket.id}`,
                    type: "success",
                  });

                  this.updateTicketRecord(
                    JSON.parse(JSON.stringify(this.newTicket))
                  );
                  this.selectedTicket = JSON.parse(
                    JSON.stringify(this.newTicket as Ticket)
                  );

                  this.$emit("onSave", {
                    ticket: this.newTicket,
                    success: true,
                  });
                  this.postClosedTicket();
                })
                .catch(() => {
                  this.isLoadingTicket = false;
                  this.addNotification({
                    message: `Error. Could not update ticket, Please Try again.`,
                    type: "error",
                  });
                  this.$emit("onSave", {
                    ticket: this.newTicket,
                    success: true,
                  });
                });
            })
            .catch(() => {
              this.isLoadingTicket = false;
              this.addNotification({
                message: `Could not comment on ticket Id ${this.newTicket.id}`,
                type: "error",
              });
              this.$emit("onTicketBilling", { success: false });
            });
        } else {
          this.postClosedTicket();
        }
      }
    },
    updateTicketStatus() {
      const oldTicket = this.getTickets.slice(
        this.first,
        this.first! + this.rows!
      )[this.active!]; // obtain from store

      this.isLoadingTicket = true;

      if (
        this.newTicket.closed == "Y" &&
        this.saveOptions[1].label == "Close"
      ) {
        this.newTicket.closed = "Y";
      } else if (
        (this.newTicket.closed == "N" || this.newTicket.closed == "") &&
        this.saveOptions[1].label == "Open"
      ) {
        this.newTicket.closed = "N";
      } else if (this.newTicket.closed == "N" || this.newTicket.closed == "") {
        this.newTicket.closed = "Y";
      } else {
        this.newTicket.closed = "N";
      }

      const ticketStatus =
        this.newTicket.closed == "N" || this.newTicket.closed == "";

      this.ticketService
        .updateTicket({
          newTicket: this.newTicket,
          oldTicket: oldTicket,
          ticket_id: this.newTicket.id,
        })
        .then(() => {
          this.isLoadingTicket = false;
          this.newBill = {} as Bill;
          this.resetBill();
          this.addNotification({
            message: `Successfully ${
              ticketStatus ? "opened" : "closed"
            } ticket Id ${this.newTicket.id}`,
            type: "success",
          });
          this.$emit("onStatusUpdate", {
            ticket: this.newTicket,
            success: true,
          });
        })
        .catch(() => {
          this.isLoadingTicket = false;
          this.newBill = {} as Bill;
          this.resetBill();
          this.addNotification({
            message: `Failed to ${ticketStatus ? "open" : "close"} ticket Id ${
              this.newTicket.id
            }`,
            type: "error",
          });
          this.$emit("onStatusUpdate", {
            ticket: this.newTicket,
            success: false,
          });
        });
    },
    setBillable(event: string) {
      this.getBillingTypes.map((element: BillingTypeItem) => {
        if (event == element.billing_type) {
          this.billable = element.billable as unknown as boolean;
        }
      });
    },
    resetFields() {
      this.newTicket = {} as Ticket;
      this.newBill = {} as Bill;
    },
    resetBill() {
      this.newBill = {} as Bill;
      this.newBill.user = this.getUser.user_id;
      this.newBill.date = "" as unknown as string;
    },
     initFields() {
      this.newTicket = {} as Ticket;
      this.resetBill();

      this.newTicket.assigned_to = this.getUser.user_id;
      this.newTicket.date = new Date() as unknown as string;

      this.billable = false;
      this.newTicketCust = "";
      this.submitted = false;
      this.part = null;
    },
    postClosedTicket() {
      return new Promise((resolve, reject) => {
        // CLOSE TICKET
        this.newTicket.closed = "";
        const closedNewTicket = JSON.parse(JSON.stringify(this.newTicket));
        closedNewTicket.closed = "Y";
        this.ticketService
          .updateTicket({
            newTicket: closedNewTicket,
            oldTicket: this.newTicket,
            ticket_id: this.newTicket.id,
          })
          .then(() => {
            this.addNotification({
              message: `Successfully updated and closed ticket Id ${this.newTicket.id}`,
              type: "success",
            });
            this.isLoadingTicket = false;
            this.submitted = false;
            this.newTicket.closed = "Y";
            this.updateTicketRecord(
              JSON.parse(JSON.stringify(closedNewTicket))
            );
            this.selectedTicket = JSON.parse(JSON.stringify(closedNewTicket));
            resolve(true);
            this.$emit("onStatusUpdate", { success: true });
          })
          .catch((error) => {
            this.addNotification({
              message: `Could not close ticket Id ${this.newTicket.id}. ${error.message}.`,
              type: "error",
            });
            this.isLoadingTicket = false;
            reject(false);
            this.$emit("onStatusUpdate", { success: false });
          });
      });
    },
  },
  watch: {
    show(newShow) {
      this.isHidden = newShow;
      this.initFields();
    },
    active(newActive) {
      if (this.ticket != null) {
        this.newTicket = this.ticket as Ticket;
        this.part = (this.ticket as Ticket).part === "" ? null : (this.ticket as Ticket).part;
        this.selectedTicket = JSON.parse(JSON.stringify(this.ticket as Ticket));

        (this.ticket as Ticket).closed == "" ||
        (this.ticket as Ticket).closed == "N"
          ? (this.saveOptions[1].label = "Close")
          : (this.saveOptions[1].label = "Open");
        
        if (
          this.getCurrentIndex == -1 &&
          this.getTickets.slice(this.first, this.first! + this.rows!)[
            this.active!
          ]?.id == this.newTicket?.id
        ) {
          this.updateCurrentIndex(newActive);
          this.initCust();
          this.setBillable(this.newTicket.type);
        }
      } else {
        this.initFields();
      }
    },
  },
});
